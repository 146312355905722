@import '~base/contactUs';
@import './global/global';
@import './bootstrapOverrides';
@import './global/variables';

.pills-card-selector {
    position: relative;
    margin-top: rem(24);
    margin-bottom: rem(40);
    border-bottom: 1px solid $account-cards-selector-border-color;
    border-top: 1px solid $account-cards-selector-border-color;

    .pills-card-select-btn {
        width: 100%;
        padding: 0 rem(16);
        text-align: left;
        text-decoration: none;

        &::after {
            position: absolute;
            content: '';
            right: rem(16);
            top: rem(14);
            transform: rotate(45deg);
            height: rem(12);
            width: rem(12);
            border-bottom: rem(1) solid $account-cards-selector-arrow-color;
            border-right: rem(1) solid $account-cards-selector-arrow-color;
        }

        &[aria-expanded='true'] {
            &::after {
                transform: rotate(225deg);
                top: rem(22);
            }
        }
    }

    .pill-cards-menu {
        margin: 0;
        border: rem(1) solid $account-cards-selector-border-color;
        box-shadow: 0 rem(4) rem(12) 0 rgba(0, 0, 0, 0.1);
    }

    .pill-card-btn {
        font-family: $gotham-regular;
        padding: 0 rem(16);
        width: 100%;
        text-align: left;
    }
}

.customer-service,
.loyalty-terms-condition {
    h1 {
        font-family: $crimson;
        font-size: $font-size-6;
        line-height: rem(40);
        color: $base-text-color;
        border-bottom: rem(1) solid $contact-us-border-color;
        padding-bottom: rem(32);
        text-transform: uppercase;
    }
}

h2 {
    font-family: $gotham-bold;
    font-size: $font-size-5;
    line-height: rem(20);
    letter-spacing: rem(1.28);
    text-transform: uppercase;
    font-weight: $font-weight-medium;

    &.email-heading {
        font-size: $font-size-14;
        margin-top: rem(32);
        margin-bottom: rem(12);
        font-family: $font-medium;

        @include media-breakpoint-up(sm) {
            margin-top: rem(24);
            margin-bottom: rem(12);
        }
    }

    &.chat-heading {
        margin-top: rem(100);
        font-size: $font-size-14;
        letter-spacing: rem(1.28);
        line-height: rem(20);
        font-weight: $font-weight-medium;
        margin-bottom: rem(12);
        font-family: $font-medium;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-12;
            line-height: rem(24);
            font-weight: $font-weight-regular;
            margin-top: rem(22);

        }
    }

    &.hours-heading {
        font-size: $font-size-14;
        margin-top: rem(56);
        margin-bottom: rem(12);
        letter-spacing: rem(1.28);
        font-family: $font-medium;

        @include media-breakpoint-down(sm) {
            margin-top: rem(24);
        }
    }

    &.call-heading,
    &.sms-heading {
        font-size: $font-size-14;
        margin-top: rem(56);
        margin-bottom: rem(12);
        letter-spacing: rem(1.28);
        line-height: rem(20);
        font-weight: $font-weight-medium;
        font-family: $font-medium;

        @include media-breakpoint-down(sm) {
            margin-top: rem(40);
        }
    }

    &.help-heading {
        font-size: $font-size-14;
        line-height: rem(20);
        text-transform: uppercase;
        margin-bottom: 0;
        letter-spacing: rem(1.12);
        font-weight: $font-weight-medium;
        font-family: $font-medium;
        padding-top: rem(6);

        @include media-breakpoint-up(md) {
            padding-top: rem(48);
        }
    }
}

.help-description,
.inquiries-description,
.call-description,
.sms-description {
    font-size: $font-size-14;
    line-height: rem(20);
    font-weight: $font-weight-regular;
    letter-spacing: rem(0.8);
    margin-bottom: rem(0);
}

.inquiries-description {
    margin-bottom: rem(8);
}

.help-description {
    font-family: $font-medium;
    font-size: $font-size-10;
    margin-bottom: rem(12);
}

.call-us-section,
.sms-section {
    a {
        display: block;
        margin-bottom: rem(16);
        display: inline-block;
    }
}


.sms-icon {
    vertical-align: text-top;
}

.contactus-card {
    border: none;
}

.faq {
    .title {
        margin-top: rem(24);
    }

    .selected-pill {
        color: $base-text-color;
        font-family: $gotham-bold;
        font-size: $font-size-12;
        letter-spacing: rem(1);
        line-height: rem(14);
    }

    .expand-all {
        position: absolute;
        right: 0;
        text-decoration: underline;
        color: $base-text-color;
        font-family: $gotham-regular;
        font-size: $font-size-12;
        font-weight: $font-weight-light;
        letter-spacing: rem(0.4);
        line-height: rem(24);
    }

    .faq-tabs {
        color: $base-text-color;
        font-family: $gotham-semibold;
        font-size: $font-size-14;
        letter-spacing: rem(0.35);
        line-height: rem(24);

        &::after {
            position: relative;
            right: rem(16);
        }
    }

    .question {
        .btn {
            display: flex;
        }

        .question-text {
            width: calc(100% - 20px);
        }
    }
}

.loyalty-terms-condition {
    z-index: 1051;

    @include media-breakpoint-up(sm) {
        top: 5%;
    }
}

.loyalty-terms-condition-dialog {
    &.modal-dialog {
        max-width: unset;
        margin: 0;
    }

    >.modal-content {
        border: transparent;

        >.modal-body {
            max-height: none;
            padding: 0;
        }
    }

    .modal-header {
        background-color: inherit;
    }

    .modal-body {
        max-height: unset;
    }

    .close-terms-condition-modal {
        padding-top: rem(16);
        line-height: rem(16);
        position: absolute;
        top: rem(10);
        right: rem(20);
        z-index: 1051;

        @include media-breakpoint-down(sm) {
            padding-top: rem(3);
            top: rem(16);
            right: rem(32);
        }
    }

    @include media-breakpoint-up(md) {
        &.modal-dialog {
            max-width: rem(960);
            margin: rem(8) auto;
        }

        >.modal-content {
            max-height: rem(520);

            >.modal-body {
                padding: 0;
            }
        }
    }

    .active-link {
        padding: 0 rem(24);

        @include media-breakpoint-down(sm) {
            padding-left: rem(12);
            padding-right: rem(12);
        }
    }

    .loyalty-terms-condition-modal {
        margin: rem(80) 0 rem(16);
        max-height: rem(420);

        @include media-breakpoint-down(sm) {
            max-height: rem(630);
            margin: rem(16) 0;
        }
    }

    h1 {
        color: $base-text-color;
        font-family: $gotham-semibold;
        font-size: $font-size-5;
        letter-spacing: rem(2);
        line-height: rem(32);
        padding-bottom: rem(16);
        margin-bottom: rem(24);
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-12;
            line-height: rem(24);
            letter-spacing: rem(1);
            margin-bottom: rem(16);
        }
    }

    .sub-header {
        font-size: $font-size-14;
        line-height: rem(20);

        @include media-breakpoint-down(sm) {
            margin-bottom: rem(24);
        }
    }

    .content-header {
        padding-bottom: rem(16);
    }

    .content {
        font-size: $font-size-12;
    }
}

.accordion-container {
    &:first-child .card {
        border-top: 0;
    }

    .card-body {

        .content {
            margin-top: rem(16);
        }
    }

    .card-header {
        .btn {
            .icon {
                position: absolute;
                right: 0;
                top: rem(4);
            }

            &::after {
                display: none;
            }
        }

        .collapsed {
            .expand-icon {
                display: inline-block;
            }

            .collapse-icon {
                display: none;
            }
        }

        .expand-icon {
            display: none;
        }

        .collapse-icon {
            display: inline-block;
        }
    }
}

.sub-header {
    color: $base-text-color;
    font-family: $gotham-semibold;
    font-size: $font-size-12;
    line-height: rem(24);
    margin-bottom: rem(40);
}

.content-header {
    color: $base-text-color;
    font-family: $gotham-semibold;
    font-size: $font-size-14;
    line-height: rem(20);
    margin-bottom: 0;
}

.content {
    color: $base-text-color;
    font-family: $gotham-regular;
    font-size: $font-size-14;
    line-height: rem(20);
    margin-bottom: rem(24);
}

.contactus-info {
    @include media-breakpoint-only(md) {
        margin-top: rem(32);
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem(138);
    }

    .contactus-header,
    .header {
        font-size: $font-size-3;
        font-weight: $font-weight-medium;
        line-height: rem(20);
        letter-spacing: rem(1.28);
        font-family: $font-medium;
        margin-top: rem(40);

        @include media-breakpoint-up(md) {
            font-size: $font-size-4;
            line-height: rem(24);
            margin-bottom: rem(16);
            letter-spacing: rem(0.8);
            margin-top: 0;
        }
    }

    .contactus-header {
        font-weight: $font-weight-regular;
        font-family: $font-primary;
    }

    .contactus-custom-radio {
        font-family: $gotham-bold;
        margin-top: rem(20);
        font-weight: $font-weight-regular;
        font-size: $font-size-14;
        line-height: rem(20);
        letter-spacing: rem(0.05);

        .form-check-label {
            font-size: $font-size-14;

            &:focus,
            &:focus-visible {
                outline: rem(0.5) solid $primary-black;
            }
        }

        input:checked+label {
            font-family: $gotham-semibold;

            &:focus,
            &:focus-visible {
                outline: rem(0.5) solid $primary-black;
            }
        }
    }

    .radio-group .sub-group {
        margin-left: rem(32);
        display: none;
    }

    .other-group {
        margin-top: rem(8);

        .form-group {
            margin-top: rem(4);
        }
    }

    .contactus-label {
        padding-top: rem(56);
        font-size: $font-size-14;
        line-height: rem(20);
        letter-spacing: rem(1.28);
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        font-family: $gotham-bold;
        margin-bottom: rem(4);
        font-family: $font-medium;

    }

    @include media-breakpoint-down(sm) {
        padding-left: rem(12);
        padding-right: rem(12);
    }
}

.general-topics {
    font-size: $font-size-14;
    line-height: rem(22);
    font-family: $font-medium;
}

.contactus-generalinfo {
    font-size: $font-size-14;

    &::after {
        position: relative;
    }
}

.top-tabs {
    font-family: $gotham-semibold;
    font-size: $font-size-14;
    line-height: rem(24);
    color: $base-text-color;

    &::after {
        position: relative;
        right: rem(16);
    }
}

.main-heading {
    @include media-breakpoint-down(sm) {
        padding-left: rem(12);
        padding-right: rem(12);
    }
}

.customer-banner {
    @include media-breakpoint-up(lg) {
        padding-left: rem(80);
        padding-right: rem(80);
    }

    @include media-breakpoint-down(sm) {
        padding-left: rem(12);
        padding-right: rem(12);
    }
}

.customer-service-container {
    li {
        font-family: $gotham-regular;
        font-size: $font-size-14;
        line-height: rem(24);
        color: $base-text-color;
        padding-top: rem(24);
        letter-spacing: rem(0.8);
    }

    .terms-conditions.content-tc {
        font-size: $font-size-3;
        line-height: rem(24);
    }
}

.custom-textarea {
    margin-top: rem(8);

    @include media-breakpoint-down(sm) {
        margin-top: rem(16);
    }
}

.contactus-textarea-label.describe-section {
    font-size: $font-size-3;
    font-weight: $font-weight-regular;
    line-height: rem(24);
    letter-spacing: rem(0.8);

    @include media-breakpoint-down(sm) {
        font-size: $font-size-14;
        line-height: rem(20);
    }
}

.contactus-card-body {
    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    @include media-breakpoint-up(lg) {
        padding-left: rem(28);
        padding-right: rem(46);
    }

    @include media-breakpoint-down(sm) {
        padding-right: rem(0);
        padding-left: rem(0);
    }
}

.contact-comment-char-count {
    margin-top: rem(8);
    margin-bottom: 0;
    font-family: $gotham-regular;
    font-size: $font-size-3;
    letter-spacing: rem(0.8);
    line-height: rem(24);

    @include media-breakpoint-down(sm) {
        font-size: $font-size-14;
        line-height: rem(20);
        letter-spacing: rem(0.8);
    }

    .radio-group>.invalid-feedback {
        margin-top: rem(24);
    }

    .radio-group.invalid-group>.invalid-feedback {
        display: block;
    }
}

.contactus-custom-radio {
    .form-check-input.is-invalid~.form-check-label {
        color: $base-text-color;
    }
}

.contact-us-submit {

    .form-group {

        margin-top: rem(40);

        @include media-breakpoint-down(sm) {
            margin-top: rem(24);
        }
    }
}

.contactus-border {

    @include media-breakpoint-down(sm) {
        border-top: rem(1) solid $contact-us-border-color;
        border-bottom: rem(1) solid $contact-us-border-color;
    }
}

.contact-us-success {
    padding: rem(16);
}

.heading-text {
    margin-top: rem(16);

}

@include media-breakpoint-up(md) {
    .expand-all {
        font-size: $font-size-14;
    }

    .top-tabs {
        li {
            color: $base-text-color;
            font-family: $gotham-regular;
            line-height: rem(20);
            text-align: center;
            margin-right: rem(20);
            margin-bottom: rem(48);

            a {
                text-decoration: none;
                padding-bottom: rem(18);

                &.active {
                    border-bottom: rem(3) solid black;
                }
            }
        }
    }
}

.customer-service-container {
    a {
        &.active {
            position: relative;
            color: $base-text-color;
            font-family: $gotham-semibold;
            line-height: rem(20);

            &::after {
                content: '';
                position: absolute;
                width: rem(8);
                height: rem(8);
                border-top: 1px solid $customer-service-active-link-arrow-color;
                border-right: 1px solid $customer-service-active-link-arrow-color;
                transform: rotate(45deg);
                top: rem(5);
                margin-left: rem(25);
            }
        }
    }
}

.sub-header {
    margin-bottom: rem(24);
}

.cs-title {
    margin-top: rem(20);
    line-height: rem(28);
    font-size: $font-size-4;
    margin-bottom: rem(40);

    @include media-breakpoint-up(md) {
        margin-top: rem(40);
        font-size: $font-size-5;
        margin-bottom: rem(60);
        line-height: rem(32);
        text-wrap: nowrap;
    }
}

.site-map-links {
    font-family: $gotham-regular;
    line-height: rem(24);
    padding-top: rem(48);

    .category-container {
        padding-bottom: rem(32);
    }

    .category-heading a {
        font-family: $gotham-semibold;
        font-size: $font-size-12;
        line-height: rem(24);
        letter-spacing: rem(0.4);
    }

    .sub-categories:empty {
        display: none;
    }

    .sub-categories-title {
        font-family: $gotham-semibold;
        font-size: $font-size-12;
        line-height: rem(24);
        letter-spacing: rem(0.4);
    }

    .category-container li a {
        text-decoration: underline;
        font-size: $font-size-14;
    }
}

.liveagent {
    padding-left: rem(12);
    padding-right: rem(12);
    line-height: rem(20);

    .live-chat-heading {
        font-size: $font-size-14;
        line-height: rem(20);
        text-transform: uppercase;
        padding: rem(40) 0 rem(12);
        letter-spacing: rem(1.12);
        font-weight: $font-weight-medium;
        font-family: $font-medium;

        @include media-breakpoint-up(md) {
            padding-top: rem(46);
        }
    }

    .live-chat-description,
    .live-chat-availability {
        font-size: $font-size-14;
        line-height: rem(20);
        letter-spacing: rem(0.8);
    }

    .live-chat-terms {
        font-size: $font-size-9;
        line-height: rem(20);
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        margin-top: rem(32);
        padding-left: rem(16);
        padding-right: rem(16);
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem(138);
        line-height: rem(20);
        padding-left: rem(80);
        padding-right: rem(62);
    }

    .chat-timings {
        font-family: $gotham-regular;
        font-size: $font-size-14;
        line-height: rem(20);
        margin-bottom: rem(24);
    }
}

.feature-blocked-message {
    line-height: rem(20);
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    letter-spacing: rem(0.8);

    @include media-breakpoint-up(sm) {

        letter-spacing: rem(0.8);
    }

}

.form-detail {
    font-size: $font-size-12;
    line-height: rem(16);
    letter-spacing: rem(0.05);
    font-weight: $font-weight-regular;

}

.button-text {
    font-size: $font-size-14;
    line-height: rem(20);
    letter-spacing: rem(0.8);
    font-weight: $font-weight-regular;
}

.main-page {
    padding-left: rem(12);
    padding-right: rem(12);

    @include media-breakpoint-down(sm) {
        padding-top: rem(12);
    }
}

.live-chat {
    margin-top: rem(10);

    @include media-breakpoint-down(md) {
        margin-top: rem(8);
    }
}

@include media-breakpoint-down(md) {

    .collapsible-md .title.contactus-generalinfo {
        line-height: rem(16);
        font-weight: $font-weight-regular;

        &::after {
            position: relative;
            top: rem(0);
            margin-left: rem(0);
            margin-top: rem(0);
            right: rem(8);
            height: rem(7);
            width: rem(7);
        }
    }
}

.contact-us {
    .custom-radio {
        .form-check-label {
            &::before {
                top: rem(3);
            }
        }

        .form-check-input {
            &:checked~.form-check-label::after {
                top: rem(7);
            }
        }
    }

    .contact-us-comment {
        .custom-textarea {
            .custom-textarea-input {
                letter-spacing: 0.05em;
            }
        }
    }
}

.contactus-card-body {
    .contact-us-success {
        color: $contact-us-success;
        letter-spacing: 0.05em;
    }
}