@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "components/formFields";
@import "components/toastMessage";

.contact-us-banner {
    background-image: url('../images/contact-us.jpg');
    background-position-y: 20%;
}

.contact-us-signup-message {
    @include toast-message();
}

.contact-us-signup-alert {
    @include toast-alert();
}
