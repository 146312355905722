@import '../pdGlobal/PdGlobal';
@import '../customerService.scss';

.show-navigation-panel-with-top-banner {

    .heading-text {
        margin: 0;
        padding: 0;

        .heading {
            margin-top: 2.5rem;
            margin-bottom: 0;
        }
    }

    .rich-text-content {
        margin-top: 2.5rem;
    }
}

.main-content-container {

    .show-navigation-panel-with-top-banner.col-12>.content-asset {
        margin-top: 2.5rem;
    }

    .col-12>.content-asset {

        .order-banner {
            width: 100%;
            font-size: rem(16);
            padding: rem(20);
            letter-spacing: rem(0.8);
            background-color: $light-clay;
        }

        .customer-service-content {

            .contactus-border {
                ul li {
                    margin-top: 1.4rem;

                    a {
                        text-decoration: none;
                    }
                }
            }

            .general-topics {
                padding-top: 0;
            }
        }
    }
}

.noBleed {
    .main-content-container {
        max-width: 100%;
        margin-left: rem(12);
        margin-right: rem(12);
    }
}

@include media-breakpoint-up(lg) {
    .main-content-container {

        .show-navigation-panel-with-top-banner.col-lg-4>.content-asset {
            margin-top: 8.75rem;

            .order-banner {
                position: absolute;
                width: 96vw;
                max-width: rem(1342);
                font-size: rem(16);
                top: 2.5rem;
                padding: rem(36) rem(32);
                background-color: $light-clay;
            }

            .customer-service-title {
                font-size: 1.5rem;
            }
        }

        .customer-service {
            .contactus-border {
                margin-top: 3.5rem;
            }
        }

        .heading-text {
            margin: 0;
            padding: 0;

            .heading {
                margin-top: 0;
            }
        }

        .show-navigation-panel-with-top-banner.col-lg-8 {
            padding-top: 15.4rem;
        }

        .show-navigation-panel-only {
            padding-top: 0;
        }
    }

    .noBleed {
        .main-content-container {
            max-width: rem(1342);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .customer-service {
        margin-top: 18.4%;
    }
}
.about-johnny-was{
    .main-content-container{
        .heading-text{
            .heading{
                @include media-breakpoint-up(md){
                margin-top: rem(36);
                }
            }
        }
    }
}
.rich-text-content {
    margin-top: 2rem;

    .heading {
        margin-top: 2rem;
        text-align: center;
    }

    // About Us- Our Story Page
    
    .text-container {
        padding: 0 rem(12);

        @include media-breakpoint-up(md) {
            padding: 0 rem(64);
        }

        .text-content-body {
            padding-left: rem(14);
            padding-right: rem(14);

            .body-text {
                margin-bottom: rem(40);
            }
        }
    }

    .main-header-title {
        font-size: $font-size-5;
        letter-spacing: 0.2rem;
        line-height: rem(32);
        padding-bottom: rem(32);
        margin-bottom: 2.5rem;
    }

    .sub-header-title {
        font-size: $font-size-5;
        line-height: rem(28);
    }

    .body-text {
        line-height: rem(22);
        letter-spacing: 0.05em;
    }

    // International Page
    .international {
        line-height: 1.25rem;

        .international-shipping-fees-table {
            #shipping-fees-table {
                font-size: $font-size-14;
            }
        }

        .international-title {
            letter-spacing: 0.2rem;
        }

        .section {
            margin-bottom: rem(30);
            letter-spacing: rem(1.54);

            .international-ulist {
                list-style-type: disc;
                padding-left: rem(40);

                ul {
                    list-style-type: circle;
                    padding-left: rem(40);
                }
            }

            .section-title {
                margin-bottom: rem(30);
                font-size: $font-size-18;
                letter-spacing: rem(3.74);
            }
        }

        .international-sub-heading {
            margin-top: rem(45);
            margin-bottom: rem(60);
            font-size: $font-size-18;
        }
        .jw-table th {
            background-color: $wildsand;
            vertical-align: middle;
            font-family: $font-primary;
            color: $primary-black;
            font-size: $font-size-14;
            border-bottom: rem(1) solid $primary-black;
        }
        .jw-table{
            line-height: rem(24);
            text-align: center;
            padding-right: rem(12);
        }
        .jw-table td{
            width: 25%;
            vertical-align: middle;
            font-size: rem(14);
            border-bottom: rem(1) solid $primary-black;
        }
    }

    // Shipping Page
    .shipping {
        .main-header-title {
            border-bottom: 0.0625rem solid $primary-black;
        }

        .jw-table {
            font-size: $font-size-14;
            line-height: rem(22);
        }

        .jw-table td{
            border-bottom: rem(1) solid $silver;
        }

        .jw-lists {
            list-style: disc outside;
        }
    }

    /** Wholesale content page **/
    .wholesale {
        .main-header-title {
            border-bottom: 0.0625rem solid $primary-black;
        }

        .showroom-location {
            .email-text {
                color: $primary-black;
                display: inline-block;
                margin: 0 auto;
            }
        }

        .query-text {
            font-size: $font-size-3;
        }

        .showroom,
        .international-quiries {
            border-bottom: rem(0.5) solid $primary-black;
        }

        .international-quiries {
            margin: rem(32) 0;
        }
    }

    //customer service content page
    .customer-service-page {
        .cs-titleblock {
            font-size: $font-size-4;
            letter-spacing: 0.08em;
            line-height: rem(42);
        }

        .content-sub-title {
            font-size: $font-size-3;
            margin-bottom: rem(40);
        }

        .cs-block-content {
            background: $lighter-clay;
            padding: rem(32) rem(16);
            margin-bottom: rem(32);

            @include media-breakpoint-up(md) {
                padding: rem(32);
            }
        }

        .ship-updates-list {
            margin-top: rem(18);

            li {
                line-height: rem(24);
                list-style-type: disc;
            }
        }

        .cslink {
            color: $primary-black;
        }
    }

    //CCPA-Rights Content Page
    .ccpa-rights {
        .main-header-title {
            padding-bottom: rem(20);
        }

        .body-text {
            margin-bottom: rem(30);
        }
    }

    /*Privacy policy content page */
    .privacy-policy-page {
        margin-bottom: rem(20);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(60);
        }

        #policy-updates,
        #ca-section {
            scroll-margin-top: rem(90);
        }

        .sub-header-title {
            font-size: $font-size-3;
        }

        .pageLink {
            cursor: pointer;
            margin-left: rem(2);
        }

        li {
            list-style: disc inside;
            line-height: rem(28);
        }

        .jw-table {
            font-size: $font-size-14;
        }

        #policy-updates {
            .main-header-title {
                border-bottom: 0.0625rem solid $primary-black;
            }

            ul.privacy-links {
                columns: 1;
                @include media-breakpoint-up(md){
                    columns: 2;
                }
                li {
                    list-style: none;
                }
            }
        }
    }

    /** Terms and Conditions content page **/
    .termsAndConditions {

        .main-header-title {
            border-bottom: 0.0625rem solid $primary-black;
        }
    }
    .body-text.last-para{
        margin-bottom: rem(100) !important;
    }

    //generic JW Blog header
    .blog-page-header {
        letter-spacing: 0.08em;
        padding: rem(15) rem(15) rem(10) rem(15);
        margin-bottom: rem(30);
    }

    //generic blog header text
    .blog-header-text {
        font-size: $font-size-35;
        line-height: rem(40);
        letter-spacing: 0.08em;
        padding: 0 rem(20);
        margin: rem(20) 0 0;

        @include media-breakpoint-up(md) {
            line-height: rem(64);
            margin: 0 auto;
            padding: 0;
            max-width: rem(850);
        }
    }

    // generic blog post date text styling
    .blogpost-date {
        font-size: $font-size-15;
        letter-spacing: 0.11em;
        line-height: rem(22);
        padding-bottom: rem(48);
        padding-top: rem(32);
    }

    // generic blog subheader text
    .blog-subheader-text {
        font-size: $font-size-18;
        line-height: rem(20);
        letter-spacing: 0.22em;

        @include media-breakpoint-up(md) {
            font-size: $font-size-6;
            line-height: rem(36);
        }
    }

    // generic blog numering header text
    .blog-numbering-header {
        font-size: $font-size-18;
        margin-bottom: rem(58);
        letter-spacing: rem(1.98);
        line-height: rem(19.8);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(88);
        }
    }

    // generic blog body text
    .blog-paragraph,
    .sub-header-text {
        font-size: $font-size-14;
        line-height: rem(28);
        padding: 0 rem(30);
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
            font-size: $font-size-4;
            line-height: rem(38);
            padding: 0 rem(40);
        }
    }

    // generic margin for paragraphs
    .para-1 {
        margin-bottom: rem(68);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(118);
        }
    }

    .para-2 {
        margin-bottom: rem(40);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(104);
        }
    }

    .para-4 {
        margin-bottom: rem(63);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(100);
        }
    }

    .para-12 {
        margin-bottom: rem(20);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(40);
        }
    }

    .para-3 {
        margin-bottom: rem(121);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(196);
        }
    }

    .para-6 {
        margin-bottom: rem(73);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(150);
        }
    }

    .para-7 {
        margin-top: rem(32);
    }
    .para-15 {
        margin-bottom: rem(56);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(136);
        }
    }

    .para-10 {
        margin-bottom: rem(104);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(214);
        }
    }

    strong, b {
        font-weight: 500;
    }

    // rich text background format
    .rich-text-background {
        padding: rem(40) rem(16);

        @include media-breakpoint-up(md) {
            padding: rem(40) 0;
            max-width: rem(670);
            margin: auto;
        }
    }
}

/* PD-Id: accessibility, website-accessibility-page */
.accessibility {
    .main-header-title {
        font-size: $font-size-42;
        margin-bottom: rem(30);
        line-height: rem(46);
    }

    .sub-header-title {
        margin-bottom: rem(30);
    }

    .body-text {
        margin-bottom: rem(10);
    }

    .main-content-container {
        max-width: rem(1440);

        @include media-breakpoint-up(md) {
            padding: 0 rem(80);
            margin: 0 auto;
        }
    }

    .rich-text-content {
        @include media-breakpoint-up(md) {
            margin-bottom: rem(40);
            margin-top: rem(35);
        }
    }
}

//PD-Id: what-to-wear-to-summer-weddings and jadecolletion
.what-to-wear-to-summer-weddings,
.inspired-notes-with-our-founder-eli-levite,
.how-to-care-for-silk-scarf,
.summer-scarf-style-guide,
.tunic-tops-three-must-have,
.santa-fe-holiday,
.scent-star-our-newest-talia-fragrance-has-arrived,
.from-italy-with-love-accessory-edit,
.how-to-wear-white-linen-pants,
.jadecolletion {
    .image-component {
        margin-bottom: rem(31);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(54);
        }
    }
    .rich-text-content {
        margin-top: 0;
    }
    a:not(.pd-default-CTA-button) {
        text-underline-offset: rem(5);
    }
}

//size guide
.size-guide {
    .rich-text-content {
        @include media-breakpoint-up(lg) {
            margin-top: rem(128);
        }

        .lp-sizechart-header .lp-sub-text {
            font-size: $font-size-5;
            line-height: 32px;
        }
        .bottom-sizeguide-btn {
            @include media-breakpoint-up(md) {
                margin-left: 0;
            }
        }
    }

    .lp-sizechart-category-dropdown {
        max-width: rem(130);
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }
}

//privacy-policy
.privacy-policy,
.international {
    .rich-text-content {
        display: flex;
        justify-content: center;
    }
}

// international
.international {
    .main-content-container {
        max-width: 100%;
        margin: 0;
    }

    .rich-text-content {
        padding: 0 rem(12);

        @include media-breakpoint-up(md) {
            padding: 0 rem(16);
        }

        @include media-breakpoint-up(lg) {
            padding: 0 rem(80);
        }
    }
}
// careers
.careers {
    .rich-text-content {
        .paragraph-1 {
            line-height: rem(20);
        }
    }
}
//star scent
.scent-star-our-newest-talia-fragrance-has-arrived {
    .blog-subheader-text {
        margin-bottom: rem(78);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(110);
        }
    }

    .blog-text-link {
        color: $bloglink-blue;
    }
}

//Tunic Tops Style Blog
.tunic-tops-three-must-have {

    .blog-subheader-text {
        padding-top: rem(30);
    }
}

//italy with love accessory edit
.from-italy-with-love-accessory-edit {
    .rich-text-content {
        margin-top: 0;
    }
}

// White Linen Blog
.how-to-wear-white-linen-pants {
    .rich-text-content {

        .sub-point,
        .blog-numbering-header {
            margin-bottom: rem(20);
        }

        .blog-subheader-text {
            margin-top: rem(32);

            @include media-breakpoint-up(md) {
                margin-top: rem(70);
            }
        }

        .sub-point {
            padding: 0 rem(30);
            
            @include media-breakpoint-up(md) {
                padding: 0 rem(40);
            }
        }
    }
}

// Garment Care Page
.garment-care {
    .wide-image-container {
        .main-content-container {
            margin: 0 rem(15);
            max-width: 100%;

            @include media-breakpoint-up(md) {
                margin: 0;
            }

            @include media-breakpoint-up(lg) {
                margin: 0 rem(46);
            }

            .heading-text {
                padding: 0;
                margin: 0 rem(24);
            }
        }

        .heading {
            margin-bottom: rem(60);
            text-align: center;

            @include media-breakpoint-up(md) {
                margin-bottom: rem(14);
                margin-top: rem(30);
            }
        }

        .rich-text-content {
            margin-top: rem(30);

            .body-text {
                margin-bottom: rem(10);
            }

            .text-header {
                font-family: $font-medium;
                font-size: $font-size-3;
                line-height: rem(18);
                margin-bottom: rem(30);
            }
        }
    }
}

.breadcrumb {
    padding-top: rem(20);
    padding-bottom: rem(20);
    margin-bottom: rem(8);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(16);
    }
}

//wholesale & Customer Service latest update
.customer-service-updates,
.returns-and-exchanges,
.wholesale,
.faqs,
.terms-and-conditions {
    .rich-text-content {
        margin-top: rem(32);

        @include media-breakpoint-up(lg) {
            margin-top: 8rem;
        }
    }
}

.shipping {
    @include media-breakpoint-up(md) {
        .rich-text-content {
            margin-top: 1.5rem;
        }
    }
}

//faqs
.faqs {
    .main-header-title {
        border-bottom: none;
        padding-bottom: 0;
        @include media-breakpoint-up(md){
            border-bottom: 0.0625rem solid $primary-black;
            padding-bottom: rem(32);
        }
    }

    .tab-border,
    .pills-card-selector {
        border-bottom: rem(0.0625) solid $primary-black;
        border-top: rem(0.0625) solid $primary-black;
    }

    .pills-card-selector {
        margin-top: rem(40);
    }

    .top-tabs {

        li {
            margin: rem(40), 0;
            a {
                text-decoration: none;
            }
        }
    }

    .accordion-container {
        .card {
            font-size: $font-size-14;
            color: $primary-black;

            .card-header {
                padding: rem(16) rem(16) rem(16) 0;

                @include media-breakpoint-up(md) {
                    margin-bottom: rem(0);
                }

                &.card-header-expand {
                    padding: rem(16) 0;
                }

                .btn {
                    text-transform: none;

                    .text-title-card {
                        color: $primary-black;
                        display: flex;
                        width: 90%;
                    }
                }
            }
        }

        .collapse-all {
            display: none;
            margin-left: rem(10);
        }

        &.m-expanded {
            .collapse-all {
                display: inline;
            }
        }

        &.m-expanded-all {
            .expand-all {
                display: none;
            }

            .collapse-all {
                display: inline;
            }
        }
    }
}

// Need Help for content pages
.need-help-section {
    margin-bottom: rem(24);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(80);
    }

    .cshelp-txt {
        margin-bottom: 2.5rem;
    }

    .help-header {
        margin-top: 4rem;
        &.divider-line {
            border-top: rem(1) solid $primary-black;
            padding-top: rem(40);
        }
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

// Returns page
.accordion-container{
    .card-body{
        .content{
            margin-top: 1rem;

            a{
                margin-left: rem(2);
            }
        }
    }
}
